import { graphql } from "gatsby"
import React, { useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import { useArticles } from "../../../hooks/useArticles"
import { isString } from "../../../utils"
import ArticleFilter from "../../molecules/ArticleFilter"
//import * as styles from "./DatoLatestArticles.module.scss"

/**
 * "Latest Articles" block.
 * Editor can pick categories and tags in CMS
 */
const DatoLatestArticles = (props: Queries.DatoLatestArticlesFragment) => {
  // console.debug("Rendering DatoLatestArticles", props)
  const { locale } = useContext(PageContext)
  const allArticles = useArticles(locale)

  const categories: string[] = props.categories?.map(itm => itm?.id).filter(isString) || []
  const tags: string[] = props.tags?.map(itm => itm?.id).filter(isString) || []

  let articles = allArticles
  // filter by categories
  if (categories?.length) {
    articles = articles.filter(article => article.articleCategory && categories.includes(article.articleCategory.id))
  }
  // filter by tags
  if (tags?.length) {
    articles = articles.filter(article =>
      tags.find(tag => article.articleTags?.find(articleTag => articleTag?.id === tag))
    )
  }

  // console.debug(`DatoLatestArticles showing ${articles.length} of ${allArticles.length} articles`)

  return <ArticleFilter title={props.title ?? undefined} categories={categories} tags={tags} articles={articles} />
}

export default DatoLatestArticles

export const query = graphql`
  fragment DatoLatestArticles on DatoCmsLatestArticleSet {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    categories {
      id: originalId
    }
    tags {
      id: originalId
    }
  }
`
