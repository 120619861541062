import React, { useContext, useEffect, useState } from "react"
import { PageContext } from "../../context/PageContext"
import useArticlePath from "../../hooks/useArticlePath"
import { ArticleCardData } from "../../hooks/useArticles"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import { isBrowser } from "../../utils"
import SubHeading from "../atoms/SubHeading"
import ArticleTileLayout from "../layouts/ArticleTileLayout"
import * as styles from "./ArticleFilter.module.scss"
import BackgroundMedia from "./BackgroundMedia"
import Pagination from "./Pagination"
import ArticleBlock from "./tileable-blocks/ArticleBlock"

type ArticleFilterProps = {
  title?: string
  categories?: string[]
  tags?: string[]
  pageSize?: number
  articles?: readonly ArticleCardData[]
}

const ArticleFilter = (props: ArticleFilterProps) => {
  // console.debug("Rendering ArticleFilter", props)
  const { locale, originalId } = useContext(PageContext)
  const t = useDatoMicrocopy("common", locale)
  const [page, setPage] = useState<number>(0)
  const getArticlePath = useArticlePath()
  const pageSize = isBrowser ? props.pageSize || 6 : 60 // rendataan ssr tilassa 60 artikkelia

  const { categories, tags, articles } = props

  const articlesKey = articles?.map(a => a.id).join("-")
  // näytetään sivu 0 jos artikkelit tai filtterit muuttuu
  useEffect(() => {
    setPage(0)
  }, [articlesKey, categories, tags])

  if (!articles) return null

  const articleBlocks = articles
    .map(article => {
      // ollaan jo kyseisessä artikkelissa
      if (originalId === article.id) return

      const url = getArticlePath(article.id, locale)

      // onko artikkeli saatavilla tällä sivustolla
      if (!article.slug || !url) {
        // console.warn(`ArticleFilter: Skipping article`, article)
        return
      }

      return (
        <ArticleBlock
          key={article.id}
          category={article.articleCategory?.name ?? undefined}
          title={article.title ?? undefined}
          excerpt={article.excerpt ?? undefined}
          image={<BackgroundMedia {...article.mainImage} />}
          url={url}
        />
      )
    })
    .filter(itm => itm)

  const nextPage = () => {
    setPage(page + 1)
  }

  return (
    <div>
      <SubHeading text={props.title} />
      {articleBlocks.length ? (
        <ArticleTileLayout>{articleBlocks.slice(0, (page + 1) * pageSize)}</ArticleTileLayout>
      ) : (
        <p className={styles.noResults}>- {t("noResults", "No results")} -</p>
      )}

      <Pagination totalElements={articleBlocks.length} page={page} onNextPage={nextPage} pageSize={pageSize} />
    </div>
  )
}

export default ArticleFilter
