import { graphql, useStaticQuery } from "gatsby"
import { DatoLocale } from "../types"

export type DatoArticleTag = NonNullable<ArticleCardData["articleTags"]>[number]

export type ArticleCardData = Queries.ArticleCardFragment

export function useArticles(locale: DatoLocale): ArticleCardData[] {
  const data = useStaticQuery<Queries.DatoArticlesQuery>(graphql`
    fragment ArticleCard on DatoCmsArticle {
      id: originalId
      slug
      mainImage {
        ...DatoImageDefault
      }
      title
      excerpt
      articleCategory {
        slug
        name
        id: originalId
      }
      articleTags {
        id: originalId
      }
    }

    query DatoArticles {
      en: allDatoCmsArticle(locale: "en", sort: { fields: datetime, order: DESC }) {
        nodes {
          ...ArticleCard
        }
      }
      fi: allDatoCmsArticle(locale: "fi", sort: { fields: datetime, order: DESC }) {
        nodes {
          ...ArticleCard
        }
      }
      sv: allDatoCmsArticle(locale: "sv", sort: { fields: datetime, order: DESC }) {
        nodes {
          ...ArticleCard
        }
      }

      de: allDatoCmsArticle(locale: "de", sort: { fields: datetime, order: DESC }) {
        nodes {
          ...ArticleCard
        }
      }
      #fr: allDatoCmsArticle(locale: "fr", sort: { fields: datetime, order: DESC }) {
      #  nodes {
      #    ...ArticleCard
      #  }
      #}
      #es: allDatoCmsArticle(locale: "es", sort: { fields: datetime, order: DESC }) {
      #  nodes {
      #    ...ArticleCard
      #  }
      #}
      #ru: allDatoCmsArticle(locale: "ru", sort: {fields: datetime, order: DESC}) {
      #  nodes {
      #    ...ArticleCard
      #  }
      #}
      #it: allDatoCmsArticle(locale: "it", sort: { fields: datetime, order: DESC }) {
      #  nodes {
      #    ...ArticleCard
      #  }
      #}
      et: allDatoCmsArticle(locale: "et", sort: { fields: datetime, order: DESC }) {
        nodes {
          ...ArticleCard
        }
      }
      #pl: allDatoCmsArticle(locale: "pl", sort: { fields: datetime, order: DESC }) {
      #  nodes {
      #    ...ArticleCard
      #  }
      #}
      en_US: allDatoCmsArticle(locale: "en-US", sort: { fields: datetime, order: DESC }) {
        nodes {
          ...ArticleCard
        }
      }
    }
  `)

  const localeKey = locale.replace("-", "_")

  return data[localeKey].nodes || []
}
